import React from 'react';

import DescriptionSection from '../../components/pages/services/threat/DescriptionSection';
import GetStartedSection from '../../components/pages/GetStartedSection';
import ConfigurationsSection from '../../components/pages/services/threat/ConfigurationsSection';
import SEO from '../../components/SEO';

const Kafka = () => {
  return (
    <>
      <SEO
        title="Organism | Threat Analysis"
        description=""
      />
      <DescriptionSection />
      <ConfigurationsSection />
      {/* <GetStartedSection /> */}
    </>
  );
};

export default Kafka;
